"use client";

import { motion } from "motion/react";
import { usePathname } from "next/navigation";
import type { ReactNode } from "react";

const variants = {
  hidden: { opacity: 0, x: 0, y: 0 },
  enter: { opacity: 1, x: 0, y: 0 },
};

export default function Template({ children }: { children: ReactNode }) {
  const pathname = usePathname();

  return (
    <motion.main
      key={pathname}
      variants={variants}
      initial={"hidden"}
      animate={"enter"}
      transition={{ ease: "easeInOut", duration: 0.75 }}
    >
      {children}
    </motion.main>
  );
}
